var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{attrs:{"centered":"","mask-closable":false,"ok-button-props":{ props: { loading: _vm.submitting } },"title":"编辑车辆"},on:{"ok":_vm.handleSubmit},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c('a-form',{staticClass:"custom-compact-form",attrs:{"form":_vm.form,"label-col":{ span: 6 },"wrapper-col":{ span: 15 }},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"车牌号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['car_no', {
            normalize: this.$lodash.trim,
            rules: [
              { required: true, message: '请输入车牌号' },
              { max: 20, message: '最多20个字符' } ]
          }]),expression:"['car_no', {\n            normalize: this.$lodash.trim,\n            rules: [\n              { required: true, message: '请输入车牌号' },\n              { max: 20, message: '最多20个字符' },\n            ]\n          }]"}],attrs:{"autocomplete":"off"}})],1),_c('a-form-item',{attrs:{"label":"图片"}},[_c('span',{staticClass:"tip-text"},[_vm._v("支持png/jpg格式照片，10M以内, 最多1张 ")]),_c('upload-image',{directives:[{name:"decorator",rawName:"v-decorator",value:(['attachments',{
            rules:[
              {validator: _vm.checkAttachments}
            ]
          }]),expression:"['attachments',{\n            rules:[\n              {validator: checkAttachments}\n            ]\n          }]"}],attrs:{"is-add-watermark":_vm.isAddWatermark,"accept":".jpg,.jpeg,.png","max-count":1,"default-file-list":_vm.defaultFileList},on:{"change":_vm.handleUploadImage}})],1),_c('a-form-item',{attrs:{"label":"工作地点"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['funeral_home_ids', {
            rules: [{ required: true, message: '请选择工作地点' }]
          }]),expression:"['funeral_home_ids', {\n            rules: [{ required: true, message: '请选择工作地点' }]\n          }]"}],attrs:{"loading":_vm.loadingFuneralHomeOptions,"mode":"multiple"}},_vm._l((_vm.funeralHomeOptions),function(home){return _c('a-select-option',{key:home.id,attrs:{"value":home.id}},[_vm._v(" "+_vm._s(home.name)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"介绍"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['remark', {
            rules: [
              { message: '请输入介绍' },
              { max: 200, message: '最多200个字符' } ]
          }]),expression:"['remark', {\n            rules: [\n              { message: '请输入介绍' },\n              { max: 200, message: '最多200个字符' },\n            ]\n          }]"}]})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }