<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="编辑车辆"
      @ok="handleSubmit"
    >
      <a-form
        class="custom-compact-form"
        :form="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 15 }"
        @submit="handleSubmit"
      >
        <a-form-item label="车牌号">
          <a-input
            v-decorator="['car_no', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入车牌号' },
                { max: 20, message: '最多20个字符' },
              ]
            }]"
            autocomplete="off"
          />
        </a-form-item>

        <a-form-item label="图片">
          <span
            class="tip-text"
          >支持png/jpg格式照片，10M以内, 最多1张
          </span>
          <upload-image
            :is-add-watermark="isAddWatermark"
            accept=".jpg,.jpeg,.png"
            :max-count="1"
            :default-file-list="defaultFileList"
            @change="handleUploadImage"
            v-decorator="['attachments',{
              rules:[
                {validator: checkAttachments}
              ]
            }]"
          />
        </a-form-item>

        <a-form-item label="工作地点">
          <a-select
            :loading="loadingFuneralHomeOptions"
            mode="multiple"
            v-decorator="['funeral_home_ids', {
              rules: [{ required: true, message: '请选择工作地点' }]
            }]"
          >
            <a-select-option
              v-for="home in funeralHomeOptions"
              :key="home.id"
              :value="home.id"
            >
              {{ home.name }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="介绍">
          <a-textarea
            v-decorator="['remark', {
              rules: [
                { message: '请输入介绍' },
                { max: 200, message: '最多200个字符' },
              ]
            }]"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import UploadImage from '@/components/Upload/Image'
import { updateCar } from '@/api/car'
import { findFuneralHomeOptions } from '@/api/funeral_home'

export default {
  name: 'EditCar',
  components: {
    UploadImage
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    record: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'car_edit' }),
      submitting: false,
      isAddWatermark: false,
      funeralHomeOptions: [],
      loadingFuneralHomeOptions: false,
      attachments: [], // 图片
      defaultFileList: [],
      data: {}
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.fetchFuneralHomeOptions()
    this.initFormData()
  },
  methods: {
    initFormData() {
      this.defaultFileList = []
      if (this.record.image) {
        const img = this.record.image
        this.defaultFileList.push({
          uid: img.uid,
          name: img.name,
          status: 'done',
          url: img.url,
          response: img.response
        })
      }
      this.data = Object.assign({}, { car_no: this.record.no, remark: this.record.remark })
      if (this.record.funeral_home_ids && this.record.funeral_home_ids.length > 0) {
        this.data = Object.assign({}, this.data, { funeral_home_ids: this.record.funeral_home_ids })
      }
      this.$nextTick(() => {
        this.form.setFieldsValue(this.data)
        this.attachments = this.defaultFileList
      })
    },

    fetchFuneralHomeOptions() {
      this.loadingFuneralHomeOptions = true
      findFuneralHomeOptions({ car_id: this.record.id }).then((res) => {
        this.funeralHomeOptions = res.data.options
        if (res.data.default_options && res.data.default_options.length > 0) {
          this.funeralHomeOptions = this.$lodash.concat(res.data.default_options, res.data.options)
          this.funeralHomeOptions = this.$lodash.uniqWith(this.funeralHomeOptions, this.$lodash.isEqual)
        }
      })
      this.loadingFuneralHomeOptions = false
    },

    handleUploadImage(fileList) {
      this.attachments = fileList
    },

    // 检查照片
    checkAttachments(rule, value, callback) {
      for (const key in this.attachments) {
        if (this.attachments[key] && this.attachments[key].status === 'error') {
          // eslint-disable-next-line standard/no-callback-literal
          callback('照片上传错误')
          return
        }
      }
      callback()
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }
      if (this.$lodash.find(this.attachments, { status: 'uploading' })) {
        this.$warning({
          title: '文件尚未上传完成',
          content: ''
        })
        return
      }
      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          if (typeof this.attachments === 'object' && this.attachments[0]) {
            values.attachment = this.attachments[0].response
          } else {
            delete values.attachment
          }
          updateCar(this.record.id, values).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>
